import { PaymentModalPricingCard } from '@/components/PaymentModalPricingCard.tsx'

type Pricing = {
  title: string
  monthlyPriceExclTax: number
  description?: string
  isDisabled?: boolean
  link?: string
}
export function PaymentModalPricingSection({
  pricingList,
}: Readonly<{
  pricingList: Pricing[]
}>) {
  return (
    <div className={'space-y-4'}>
      {pricingList.map((pricing) => (
        <PaymentModalPricingCard key={pricing.title} {...pricing} />
      ))}
    </div>
  )
}
