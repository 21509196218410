import { cn } from '@/lib/utils.ts'

interface PricingCardProps {
  title: string
  monthlyPriceExclTax: number
  description?: string // Optional since it won't be used in disabled state
  isDisabled?: boolean
  link?: string
}
export function PaymentModalPricingCard({
  title,
  monthlyPriceExclTax,
  description,
  isDisabled,
  link,
}: PricingCardProps) {
  const content = (
    <div
      className={cn(
        'flex flex-col items-center justify-center rounded-2xl pb-5 pt-4',
        isDisabled ? 'cursor-default border border-silver py-4' : 'cursor-pointer bg-primary hover:bg-volcanicSand',
      )}
    >
      <h2 className={cn('text-sm font-normal text-bright', isDisabled && 'text-flintRock line-through')}>{title}</h2>
      <p className={cn('text-2xl font-semibold text-bright', isDisabled && 'font-normal text-flintRock line-through')}>
        {monthlyPriceExclTax}€{' '}
        <span
          className={cn(
            'text-sm font-semibold text-bright',
            isDisabled && 'text-2xl font-semibold text-flintRock line-through',
          )}
        >
          HT
        </span>{' '}
        / mois
      </p>
      {!isDisabled && <p className="text-base font-semibold text-bright">{description}</p>}
    </div>
  )

  return isDisabled ? (
    <div>{content}</div>
  ) : (
    <a href={link} target="_blank" rel="noopener noreferrer" className="block">
      {content}
    </a>
  )
}
