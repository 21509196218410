import { DialogHeader, DialogTitle } from '@/components/ui/dialog.tsx'
import { PaymentModal } from '@/components/modals/PaymentModal.tsx'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'
import { PaymentModalPricingSection } from '@/components/PaymentModalPricingSection.tsx'
import { PaymentModalServiceDetails } from '@/components/PaymentModalServiceDetails.tsx'
import { PaymentModalContactFooter } from '@/components/PaymentModalContactFooter.tsx'

const stripePaymentLink = import.meta.env.VITE_STRIPE_PAYMENT_LINK
const prefilledPromoCode = import.meta.env.VITE_STRIPE_PROMO_CODE

export function PaymentModalContainer() {
  const { isPremiumUser, currentUser } = useCurrentUserHook()
  const stripeLinkWithQueryParams = `${stripePaymentLink}?prefilled_email=${currentUser?.email}+&prefilled_promo_code=${prefilledPromoCode}`
  const pricingList = [
    {
      title: 'REGULAR',
      monthlyPriceExclTax: 99,
      isDisabled: true,
    },
    {
      title: 'EARLY ADOPTER',
      monthlyPriceExclTax: 69,
      description: 'en vous abonnant avant le 30 septembre',
      link: stripeLinkWithQueryParams,
    },
  ]
  return (
    <PaymentModal isOpen={!isPremiumUser}>
      <DialogHeader className={'mb-6 space-y-1'}>
        <DialogTitle className={'text-center text-[1.75rem] font-semibold leading-9 text-primary'}>
          Olympe devient payant !
        </DialogTitle>
      </DialogHeader>
      <section className={'space-y-6 text-center'}>
        <PaymentModalServiceDetails />
        <PaymentModalPricingSection pricingList={pricingList} />
        <PaymentModalContactFooter />
      </section>
    </PaymentModal>
  )
}
