const contactFormUrl = import.meta.env.VITE_OLYMPE_CONTACT_URL

export function PaymentModalContactFooter() {
  return (
    <footer>
      <p className={'text-base'}>
        Une question ?{' '}
        <a href={contactFormUrl} className={'font-semibold'} target={'_blank'} rel={'noreferrer'}>
          Écrivez-nous.
        </a>
      </p>
    </footer>
  )
}
